import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from 'src/app/services/authService/auth.service';

export const authGuard: CanActivateFn = async (route, state) => {
  let authService = new AuthService()
  let router = new Router();
  if (await authService.isLoggedIn()) {
    return true;
  } else {
    router.navigate(['/login'])
    return false;
  }
};
