import CallablePromise from './CallablePromise';

export default class VirtualAsyncTasksQueue {
  private queue: CallablePromise<void>[] = [];

  private awaitEmpty = CallablePromise.resolve();

  whenEmpty(cb: () => void) {
    return this.awaitEmpty.then(cb);
  }

  enqueue() {
    this.queue.push(new CallablePromise());

    if (this.queue.length === 1) {
      this.run();
    }
  }

  resolveFirst(): boolean {
    return this.resolveAt(0);
  }
  resolveLast(): boolean {
    return this.resolveAt(this.queue.length - 1);
  }
  resolveAt(index: number): boolean {
    this.queue[index]?.resolve();

    return index >= 0 && index < this.queue.length;
  }
  resolveAll(): boolean {
    for (let i = 0; i < this.queue.length; i++) {
      this.resolveAt(i);
    }

    return this.queue.length > 0;
  }

  private async run() {
    const awaitEmpty = new CallablePromise<void>();
    this.awaitEmpty = awaitEmpty;

    while (this.queue.length > 0) {
      const promise = this.queue[0];
      try {
        await promise;
      } finally {
        this.queue.shift();
      }
    }

    awaitEmpty.resolve();
  }
}
