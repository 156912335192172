export default class CallablePromise<T> {
  private promise: Promise<T>;
  resolve_!: (value: T) => void;
  reject_!: (reason?: any) => void;

  constructor() {
    this.promise = new Promise((resolve, reject) => {
      this.resolve_ = resolve;
      this.reject_ = reject;
    });
  }

  resolve(value: T): CallablePromise<T> {
    this.resolve_(value);
    return this;
  }
  reject(reason?: any): CallablePromise<T> {
    this.reject_(reason);
    return this;
  }

  then<TResult1 = T, TResult2 = never>(
    onfulfilled?:
      | ((value: T) => TResult1 | PromiseLike<TResult1>)
      | undefined
      | null,
    onrejected?:
      | ((reason: any) => TResult2 | PromiseLike<TResult2>)
      | undefined
      | null
  ) {
    return this.promise.then(onfulfilled, onrejected);
  }
  catch<TResult = never>(
    onrejected?:
      | ((reason: any) => TResult | PromiseLike<TResult>)
      | undefined
      | null
  ) {
    return this.promise.catch(onrejected);
  }

  static resolve<T>(value: T): CallablePromise<T>;
  static resolve(): CallablePromise<void>;
  static resolve(value?: any): CallablePromise<any> {
    return new CallablePromise().resolve(value);
  }
}
