import { Injectable } from '@angular/core';
import VirtualAsyncTasksQueue from 'src/app/models/VirtualAsyncTasksQueue';

@Injectable({
  providedIn: 'root',
})
export class OverlayAutocloseService {
  constructor() {}

  private modalStack: HTMLIonModalElement[] = [];
  private popoverStack: HTMLIonPopoverElement[] = [];
  private popstateGaurd = false;

  private popstateQueue = new VirtualAsyncTasksQueue();

  init() {
    // @ts-ignore
    document.addEventListener('willPresent', (event: CustomEvent) => {
      const target = event.target as
        | HTMLIonPopoverElement
        | HTMLIonModalElement;

      if (target.tagName !== 'ION-MODAL' && target.tagName !== 'ION-POPOVER') {
        return;
      }

      if (target.tagName === 'ION-MODAL') {
        this.modalStack.push(target as HTMLIonModalElement);

        this.popstateQueue.whenEmpty(() => {
          // When there is no pending popstate events, we can safely
          // push a new history state.
          history.pushState(null, '');
        });

        target.addEventListener(
          'willDismiss',
          (ev) => {
            const isPopstate = (ev as any).detail.role === 'popstate';
            if (!isPopstate) {
              this.popstateGaurd = true;

              // Since history.back() is asynchronous and can only be done in a
              // popstate event handler.
              // (1) enqueue a task (resolved in popstate (2))
              history.back();
              this.popstateQueue.enqueue();
            }

            const index = this.modalStack.indexOf(
              target as HTMLIonModalElement
            );

            if (index !== -1) {
              this.modalStack.splice(index, 1);
            }
          },
          { once: true }
        );
      } else {
        this.popoverStack.push(target as HTMLIonPopoverElement);

        target.addEventListener(
          'willDismiss',
          () => {
            const index = this.popoverStack.indexOf(
              target as HTMLIonPopoverElement
            );

            if (index !== -1) {
              this.popoverStack.splice(index, 1);
            }
          },
          { once: true }
        );
      }
    });
    addEventListener('popstate', () => {
      if (this.popstateGaurd) {
        // (2) Resolve the popstate task
        this.popstateQueue.resolveFirst();

        this.popstateGaurd = false;
        return;
      }

      for (const popover of this.popoverStack) {
        popover.dismiss();
      }
      this.popoverStack = [];

      this.modalStack.pop()?.dismiss(null, 'popstate');
    });
  }
}
