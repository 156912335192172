import { Component } from '@angular/core';
import { ThemeService } from './services/theme-service/theme.service';
import { LanguageService } from './services/language-service/language.service';
import { MatIconRegistry } from '@angular/material/icon';
import { OverlayAutocloseService } from './services/overlay-autoclose-service/overlay-autoclose.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    private themeService: ThemeService,
    private languageService: LanguageService,
    private iconRegistry: MatIconRegistry,
    private overlayAutoclose: OverlayAutocloseService
  ) {
    this.themeService.loadTheme();
    this.languageService.loadLanguage();
    this.iconRegistry.setDefaultFontSetClass('material-symbols-outlined');

    this.overlayAutoclose.init();
  }
}
